import { cartDetailsFragment } from "../queries/get-cart-query";
const cartLineItemUpdateMutation = /* GraphQL */ `
  mutation cartLinesUpdate(
    $cartId: ID!
    $lines: [CartLineUpdateInput!]!
  ) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      userErrors {
        field
        message
      }
      cart {
        ...cartDetails
      }
    }
  }

  ${cartDetailsFragment}
`;
export default cartLineItemUpdateMutation;
