const News = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
          d="M18.11,4.5H2.887a.787.787,0,0,0-.787.787V5.55H.787A.787.787,0,0,0,0,6.337v8.923A1.837,1.837,0,0,0,1.837,17.1H17.323A1.575,1.575,0,0,0,18.9,15.523V5.287A.787.787,0,0,0,18.11,4.5ZM1.837,15.523a.262.262,0,0,1-.262-.262V7.125H2.1v8.136A.262.262,0,0,1,1.837,15.523ZM9.58,15H4.593A.394.394,0,0,1,4.2,14.6v-.262a.394.394,0,0,1,.394-.394H9.58a.394.394,0,0,1,.394.394V14.6A.394.394,0,0,1,9.58,15ZM16.4,15H11.417a.394.394,0,0,1-.394-.394v-.262a.394.394,0,0,1,.394-.394H16.4a.394.394,0,0,1,.394.394V14.6A.394.394,0,0,1,16.4,15ZM9.58,11.849H4.593a.394.394,0,0,1-.394-.394v-.262a.394.394,0,0,1,.394-.394H9.58a.394.394,0,0,1,.394.394v.262A.394.394,0,0,1,9.58,11.849Zm6.824,0H11.417a.394.394,0,0,1-.394-.394v-.262a.394.394,0,0,1,.394-.394H16.4a.394.394,0,0,1,.394.394v.262A.394.394,0,0,1,16.4,11.849Zm0-3.149H4.593A.394.394,0,0,1,4.2,8.306V6.993A.394.394,0,0,1,4.593,6.6H16.4a.394.394,0,0,1,.394.394V8.306A.394.394,0,0,1,16.4,8.7Z"
          transform="translate(3 1.5)"
          fill="currentColor"/>
    </svg>
  )
}

export default News
