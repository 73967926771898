import cn from 'clsx'
import Link from 'next/link'
import {FC} from 'react'
import s from './CartSidebarView.module.css'
import CartItem from '../CartItem'
import { Button, Text } from '@components/ui'
import { useUI } from '@components/ui/context'
import { Bag, Cross, Check } from '@components/icons'
import useCart from '@framework/cart/use-cart'
import usePrice from '@framework/product/use-price'
import SidebarLayout from '@components/common/SidebarLayout'
import {useTranslation} from "next-i18next";
import {ProductCard} from "@components/product";

const CartSidebarView: FC = () => {
  const { closeSidebar, setSidebarView } = useUI()
  const { data, isLoading, isEmpty }: any = useCart()

  const { price: subTotal } = usePrice(
    data && {
      amount: Number(data.subtotalPrice),
      currencyCode: data.currency.code,
    }
  )
  const { price: total } = usePrice(
    data && {
      amount: Number(data.totalPrice),
      currencyCode: data.currency.code,
    }
  )

  const handleClose = () => closeSidebar()
  const goToCheckout = () => setSidebarView('CHECKOUT_VIEW')

  const error = null
  const success = null

  const { t } = useTranslation("common");

  return (
    <SidebarLayout
      className={cn({
        [s.empty]: error || success || isLoading || isEmpty,
      })}
      handleClose={handleClose}
    >
      {isLoading || isEmpty ? (
        <div className="flex-1 px-4 flex flex-col justify-center items-center">
          <span className="border border-dashed border-primary rounded-full flex items-center justify-center w-16 h-16 p-12 bg-secondary text-secondary">
            <Bag className="absolute" />
          </span>
          <h2 className="pt-6 text-2xl font-bold tracking-wide text-center">
            {t("cart-empty")}
          </h2>
          <p className="text-accent-3 px-10 text-center pt-2">
            {t("cart-empty-body")}
          </p>
        </div>
      ) : error ? (
        <div className="flex-1 px-4 flex flex-col justify-center items-center">
          <span className="border border-white rounded-full flex items-center justify-center w-16 h-16">
            <Cross width={24} height={24} />
          </span>
          <h2 className="pt-6 text-xl font-light text-center">
            {t("checkout-error")}
          </h2>
        </div>
      ) : success ? (
        <div className="flex-1 px-4 flex flex-col justify-center items-center">
          <span className="border border-white rounded-full flex items-center justify-center w-16 h-16">
            <Check />
          </span>
          <h2 className="pt-6 text-xl font-light text-center">
            {t("checkout-success")}
          </h2>
        </div>
      ) : (
        <>
          <div className="px-4 sm:px-6 flex-1">
            <Link legacyBehavior href="/cart">
              <a>
                <Text variant="sectionHeading" onClick={handleClose}>
                  {t("my-cart")}
                </Text>
              </a>
            </Link>
            <ul className={s.lineItemsList}>
              {data!.lineItems.map((item: any) => (
                <CartItem
                  key={item.id}
                  item={item}
                  currencyCode={data!.currency.code}
                />
              ))}
            </ul>
          </div>

          {
            data && data.recommendedProducts ? <section className="py-8 px-6 mb-10">
              <Text variant="sectionHeading">{t("recommended-products")}</Text>
              <div className={cn(s.relatedProductsGrid, 'grid grid-cols-3')}>
                {data.recommendedProducts.map((p: any) => (
                  <div
                    key={p.path}
                    className="animated fadeIn bg-accent-0"
                  >
                    <ProductCard
                      onClick={handleClose}
                      noNameTag
                      product={p}
                      key={p.path}
                      variant="slim"
                      className="animated fadeIn"
                      imgProps={{
                        alt: '', // todo
                        width: 300,
                        height: 300,
                      }}
                    />
                  </div>
                ))}
              </div>
            </section> : null
          }

          <div className="flex-shrink-0 px-6 py-6 sm:px-6 sticky z-20 bottom-0 w-full right-0 left-0 bg-accent-0 border-t text-sm">

            {
              (data && data.totalPrice < 100) ?
                <div className="flex items-center bg-accent-1 text-sm font-bold px-4 py-5 w-100 mb-3" role="alert">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                       stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                       className="feather feather-truck mx-3">
                    <rect x="1" y="3" width="15" height="13"></rect>
                    <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                    <circle cx="5.5" cy="18.5" r="2.5"></circle>
                    <circle cx="18.5" cy="18.5" r="2.5"></circle>
                  </svg>
                  <ul className="w-100">
                    <li className="ml-2">🇩🇪 {t("free-shipping-germany")}</li>
                    <li className="mt-2 ml-2">🇪🇺 {t("free-shipping-eu")}</li>
                    {/*<li className="mt-2 ml-2">🌍 {t("free-shipping-international")}</li>*/}
                  </ul>
                </div> : null
            }

            <ul className="pb-2">
              <li className="flex justify-between py-1">
                <span>{t("subtotal")}</span>
                <span>{subTotal}</span>
              </li>
              <li className="flex justify-between py-1">
                <span>{t("taxes")}</span>
                <span>{t("calculated-at-checkout")}</span>
              </li>
              {/*<li className="flex justify-between py-1">
                <span>{t("shipping")}</span>
                <span>{t("calculated-at-checkout")}</span>
              </li>*/}
            </ul>
            <div className="flex justify-between border-t border-accent-2 py-3 font-bold mb-2">
              <span>{t("total")}</span>
              <span>{total}</span>
            </div>
            <div>
              {process.env.COMMERCE_CUSTOMCHECKOUT_ENABLED ? (
                <Button Component="a" width="100%" onClick={goToCheckout}>
                  {t("proceed-to-checkout")} ({total})
                </Button>
              ) : (
                <Button href="/checkout" Component="a" width="100%">
                  {t("proceed-to-checkout")}
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </SidebarLayout>
  )
}

export default CartSidebarView
