import { CommerceError } from "@vercel/commerce/utils/errors";
import { normalizeCart } from "./normalize";
import throwUserErrors from "./throw-user-errors";
const validateCart = (cartPayload)=>{
    throwUserErrors(cartPayload == null ? void 0 : cartPayload.userErrors);
    console.log("VALIDATE CART", cartPayload);
    if (!(cartPayload == null ? void 0 : cartPayload.checkoutUrl)) {
        throw new CommerceError({
            message: "Missing checkout url from response"
        });
    }
    return normalizeCart(cartPayload);
};
export default validateCart;
