import { FC } from 'react'
import { useUI } from '@components/ui/context'

interface Props {}

const SizeTableModal: FC<Props> = () => {
  // Form State
  const { setModalView, closeModal, modalContent } = useUI()

  return (
    <form className="flex flex-col justify-between p-3 h-[80vh]"
    >
      <div style={{overflow: "scroll"}}>
        {
          modalContent ? <div dangerouslySetInnerHTML={{__html: modalContent}}></div> :
            <span>No size guide found</span>
        }
      </div>
    </form>
  )
}

export default SizeTableModal
