import { colorMap } from "./colors";
const money = ({ amount , currencyCode  })=>{
    return {
        value: +amount,
        currencyCode
    };
};
const normalizeProductOption = ({ id , name: displayName , values  })=>{
    return {
        __typename: "MultipleChoiceOption",
        id,
        displayName: displayName.toLowerCase(),
        values: values.map((value)=>{
            let output = {
                label: value
            };
            if (displayName.match(/colou?r/gi)) {
                const mapedColor = colorMap[value.toLowerCase().replace(/ /g, "")];
                if (mapedColor) {
                    output = {
                        ...output,
                        hexColors: [
                            mapedColor
                        ]
                    };
                }
            }
            return output;
        })
    };
};
const normalizeProductImages = ({ edges  })=>{
    return edges == null ? void 0 : edges.map(({ node: { originalSrc: url , ...rest }  })=>({
            // @ts-ignore
            url,
            ...rest
        }));
};
export const normalizeProductVariants = ({ edges  })=>{
    return edges == null ? void 0 : edges.map(({ node: { id , selectedOptions , sku , title , priceV2 , compareAtPriceV2 , requiresShipping , availableForSale  }  })=>{
        return {
            id,
            name: title,
            sku: sku ?? id,
            price: +priceV2.amount,
            listPrice: +(compareAtPriceV2 == null ? void 0 : compareAtPriceV2.amount),
            requiresShipping,
            availableForSale,
            options: selectedOptions.map(({ name , value  })=>{
                const options = normalizeProductOption({
                    id,
                    name,
                    values: [
                        value
                    ]
                });
                return options;
            })
        };
    });
};
export function normalizeProduct({ id , title: name , vendor , images , variants , availableForSale , description , descriptionHtml , handle , priceRange , options , metafields , ...rest }) {
    return {
        id,
        name,
        availableForSale,
        vendor,
        path: `/${handle}`,
        slug: handle == null ? void 0 : handle.replace(/^\/+|\/+$/g, ""),
        price: money(priceRange == null ? void 0 : priceRange.minVariantPrice),
        images: normalizeProductImages(images),
        variants: variants ? normalizeProductVariants(variants) : [],
        options: options ? options.filter((o)=>o.name !== "Title") // By default Shopify adds a 'Title' name when there's only one option. We don't need it. https://community.shopify.com/c/Shopify-APIs-SDKs/Adding-new-product-variant-is-automatically-adding-quot-Default/td-p/358095
        .map((o)=>normalizeProductOption(o)) : [],
        metafields: metafields ? metafields : [],
        ...description && {
            description
        },
        ...descriptionHtml && {
            descriptionHtml
        },
        ...rest
    };
}
export function normalizeCart(cart) {
    var _cart_cost, _cart_cost_totalAmount, _cart_lines, _cart_lines1, _cart_cost_subtotalAmount, _cart_cost_subtotalAmount1, _cart_cost1, _cart_cost_totalAmount1;
    return {
        id: cart.id,
        url: cart.checkoutUrl,
        customerId: "",
        email: "",
        createdAt: cart.createdAt,
        currency: {
            code: (_cart_cost = cart.cost) == null ? void 0 : (_cart_cost_totalAmount = _cart_cost.totalAmount) == null ? void 0 : _cart_cost_totalAmount.currencyCode
        },
        // taxesIncluded: checkout.taxesIncluded,
        lineItems: (_cart_lines = cart.lines) == null ? void 0 : _cart_lines.edges.map(normalizeLineItem),
        lines: (_cart_lines1 = cart.lines) == null ? void 0 : _cart_lines1.edges.map(normalizeLineItem),
        lineItemsSubtotalPrice: +((_cart_cost_subtotalAmount = cart.cost.subtotalAmount) == null ? void 0 : _cart_cost_subtotalAmount.amount),
        subtotalPrice: +((_cart_cost_subtotalAmount1 = cart.cost.subtotalAmount) == null ? void 0 : _cart_cost_subtotalAmount1.amount),
        totalPrice: (_cart_cost1 = cart.cost) == null ? void 0 : (_cart_cost_totalAmount1 = _cart_cost1.totalAmount) == null ? void 0 : _cart_cost_totalAmount1.amount,
        discounts: []
    };
}
function normalizeLineItem({ node: { id , merchandise , quantity  }  }) {
    var _merchandise_product, _merchandise_image, _merchandise_priceV2, _merchandise_compareAtPriceV2, _merchandise_product1;
    return {
        id,
        variantId: String(merchandise == null ? void 0 : merchandise.id),
        productId: String(merchandise == null ? void 0 : (_merchandise_product = merchandise.product) == null ? void 0 : _merchandise_product.id),
        product: merchandise == null ? void 0 : merchandise.product,
        name: `${merchandise.title}`,
        quantity: quantity,
        variant: {
            id: String(merchandise == null ? void 0 : merchandise.id),
            sku: (merchandise == null ? void 0 : merchandise.sku) ?? "",
            name: merchandise == null ? void 0 : merchandise.title,
            image: {
                url: (merchandise == null ? void 0 : (_merchandise_image = merchandise.image) == null ? void 0 : _merchandise_image.originalSrc) || "/product-img-placeholder.svg"
            },
            requiresShipping: (merchandise == null ? void 0 : merchandise.requiresShipping) ?? false,
            price: merchandise == null ? void 0 : (_merchandise_priceV2 = merchandise.priceV2) == null ? void 0 : _merchandise_priceV2.amount,
            listPrice: merchandise == null ? void 0 : (_merchandise_compareAtPriceV2 = merchandise.compareAtPriceV2) == null ? void 0 : _merchandise_compareAtPriceV2.amount
        },
        path: String(merchandise == null ? void 0 : (_merchandise_product1 = merchandise.product) == null ? void 0 : _merchandise_product1.handle),
        discounts: [],
        options: (merchandise == null ? void 0 : merchandise.title) == "Default Title" ? [] : merchandise == null ? void 0 : merchandise.selectedOptions
    };
}
export const normalizePage = ({ title: name , handle , ...page }, locale = "en-US")=>({
        ...page,
        url: `/${locale}/${handle}`,
        name
    });
export const normalizePages = (edges, locale)=>{
    return edges == null ? void 0 : edges.map((edge)=>normalizePage(edge.node, locale));
};
export const normalizeCategory = ({ title: name , handle , id , image  })=>({
        id,
        name,
        slug: handle,
        path: `/${handle}`,
        image: image
    });
