import { cartDetailsFragment } from "../queries/get-cart-query";
const cartCreateMutation = /* GraphQL */ `
  mutation cartCreate($input: CartInput = {}) {
    cartCreate(input: $input) {
      userErrors {
        field
        message
      }
      cart {
        ...cartDetails
      }
    }
  }

  ${cartDetailsFragment}
`;
export default cartCreateMutation;
