import { FC } from 'react'
import cn from 'clsx'
import Link from 'next/link'
import type { Product } from '@commerce/types/product'
import s from './ProductCard.module.scss'
import Image, { ImageProps } from 'next/image'
import WishlistButton from '@components/wishlist/WishlistButton'
import usePrice from '@framework/product/use-price'
import ProductTag from '../ProductTag'
import {useTranslation} from "next-i18next";

interface Props {
  className?: string
  product: Product
  noNameTag?: boolean
  onClick?: any
  imgProps?: Omit<ImageProps, 'src' | 'layout' | 'placeholder' | 'blurDataURL'>
  variant?: 'default' | 'slim' | 'simple'
}

const placeholderImg = '/product-img-placeholder.svg'

const ProductCard: FC<Props> = ({
  product,
  imgProps,
  className,
  noNameTag = false,
  variant = 'default',
                                  onClick
}) => {
  const { price } = usePrice({
    amount: product.price?.value,
    baseAmount: product.price?.retailPrice,
    currencyCode: product.price?.currencyCode!,
  })

  const rootClassName = cn(
    s.root,
    { [s.slim]: variant === 'slim', [s.simple]: variant === 'simple' },
    className
  )

  const { t } = useTranslation("common");

  return (
    <Link legacyBehavior href={`/shop/${product.slug}`}>
      <a onClick={onClick} className={rootClassName} aria-label={product.name}>
        {variant === 'slim' && (
          <>
            <div className={s.imageContainer}>
              {product?.images && (
                <div>
                  <Image
                    alt={product.name || 'Product Image'}
                    className={s.productImage}
                    src={product.images[0]?.url || placeholderImg}
                    height={540}
                    width={540}
                    quality="85"
                    layout="responsive"
                    {...imgProps}
                  />
                  {/*{
                    !product.availableForSale && <p className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -rotate-45 bg-teal-600/70 p-2 font-extrabold">
                      {t("not-available")}
                    </p>
                  }*/}
                </div>
              )}
            </div>

            <div className="left-0 z-20 bg-white w-full text-center px-2 pt-3">
              <ProductTag
                name={product.name}
                price={`${price}`}
                availableForSale={product.availableForSale}
                originalPrice={product.price?.value}
                comparePrice={product.variants[0].listPrice}
                currency={product.price?.currencyCode!}
              />
            </div>
          </>
        )}

        {variant === 'simple' && (
          <>
            {process.env.COMMERCE_WISHLIST_ENABLED && (
              <WishlistButton
                className={s.wishlistButton}
                productId={product.id}
                variant={product.variants[0]}
              />
            )}
            {!noNameTag && (
              <div className={s.header}>
                <h3 className={s.name}>
                  <span>{product.name}</span>
                </h3>
                <div className={s.price}>
                  {`${price}`}
                </div>
              </div>
            )}
            <div className={s.imageContainer}>
              {product?.images && (
                <div>
                  <Image
                    alt={product.name || 'Product Image'}
                    className={s.productImage}
                    src={product.images[0]?.url || placeholderImg}
                    height={540}
                    width={540}
                    quality="85"
                    layout="responsive"
                    {...imgProps}
                  />
                </div>
              )}
            </div>
          </>
        )}

        {variant === 'default' && (
          <>
            {process.env.COMMERCE_WISHLIST_ENABLED && (
              <WishlistButton
                className={s.wishlistButton}
                productId={product.id}
                variant={product.variants[0] as any}
              />
            )}
            <div className={s.imageContainer}>
              {product?.images && (
                <div>
                  <Image
                    alt={product.name || 'Product Image'}
                    className={s.productImage}
                    src={product.images[0]?.url || placeholderImg}
                    height={540}
                    width={540}
                    quality="85"
                    layout="responsive"
                    {...imgProps}
                  />
                </div>
              )}
            </div>
            <div className="z-20 bg-white p-5">
              <ProductTag
                name={product.name}
                availableForSale={product.availableForSale}
                price={`${price}`}
                originalPrice={product.price?.value}
                comparePrice={product.variants[0].listPrice}
                currency={product.price?.currencyCode!}
              />
            </div>
          </>
        )}
      </a>
    </Link>
  )
}

export default ProductCard
