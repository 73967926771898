import cn from 'clsx'
import { inherits } from 'util'
import s from './ProductTag.module.css'

interface ProductTagProps {
  className?: string
  name: string
  availableForSale: boolean
  price: string
  currency: string
  comparePrice?: number | undefined | null
  originalPrice?: number | undefined | null
  fontSize?: number
}

const ProductTag: React.FC<ProductTagProps> = ({
  name,
  availableForSale,
  price,
  comparePrice,
  originalPrice,
  currency,
  className = '',
  fontSize = 32,
}) => {
  return (
    <>
    <div className={cn(s.root, className)}>
      <h3 className={s.name}>
        <span
          className={cn({ [s.fontsizing]: fontSize < 32 })}
        >
          {name}
        </span>
      </h3>
      <div className={s.price}>
        {(Math.round((originalPrice || 0) * 100) / 100).toFixed(2)} {currency}
        {
          comparePrice &&
          typeof comparePrice === 'number' && // can be NaN so check!
          comparePrice !== originalPrice ?
            <span className="text-accent-3 mt-1 ml-2 bg-primary" style={{textDecoration: 'line-through'}}>{(Math.round((comparePrice || 0) * 100) / 100).toFixed(2)} {currency}</span> : null
        }
      </div>
    </div>
    </>
  )
}

export default ProductTag
