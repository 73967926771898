import { useMemo } from "react";
import useCommerceCart from "@vercel/commerce/cart/use-cart";
import { normalizeCart, normalizeProduct } from "../utils";
import { getProductRecommendationsQuery } from "../utils/queries/get-checkout-query";
import getCartQuery from "../utils/queries/get-cart-query";
import Cookies from "js-cookie";
import { SHOPIFY_CART_ID_COOKIE, SHOPIFY_CHECKOUT_ID_COOKIE, SHOPIFY_CHECKOUT_URL_COOKIE } from "../const";
export default useCommerceCart;
export async function addRecommendedProducts(cart, products, fetch) {
    try {
        let recommendedProducts = [];
        for(let i = 0; i < products.length; i++){
            let product = products[i];
            if (recommendedProducts && recommendedProducts.length > 4) {
                break;
            }
            let { productRecommendations  } = await fetch({
                method: undefined,
                url: undefined,
                query: getProductRecommendationsQuery,
                variables: {
                    productId: product.id
                }
            });
            recommendedProducts = [
                ...recommendedProducts,
                ...productRecommendations
            ];
        }
        if (!recommendedProducts) {
            return cart;
        }
        recommendedProducts = recommendedProducts.slice(0, 3);
        recommendedProducts = recommendedProducts.map((p)=>{
            return normalizeProduct(p);
        });
        cart.recommendedProducts = recommendedProducts.slice(0, 10);
        return cart;
    } catch (e) {
        return cart;
        console.error(e);
    }
}
export const handler = {
    fetchOptions: {
        query: getCartQuery
    },
    async fetcher ({ input: { cartId  } , options , fetch  }) {
        if (cartId) {
            let { node  } = await fetch({
                ...options,
                variables: {
                    cartId: cartId
                }
            });
            console.log("node", node);
            if ((node == null ? void 0 : node.completedAt) || !node) {
                console.log("NO NODE RETURNED, REMOVING CART COOKIE");
                Cookies.remove(SHOPIFY_CHECKOUT_ID_COOKIE);
                Cookies.remove(SHOPIFY_CART_ID_COOKIE);
                Cookies.remove(SHOPIFY_CHECKOUT_URL_COOKIE);
                return null;
            } else {
                let cart = normalizeCart(node);
                if (!cart) {
                    return null;
                }
                console.log("cart 2", cart);
                const lines = cart.lines;
                const products = lines.map((lineItem)=>lineItem.product);
                cart = await addRecommendedProducts(cart, products, fetch);
                console.log("cart 3", cart);
                return cart;
            }
        }
        return null;
    },
    useHook: ({ useData  })=>{
        return (input)=>{
            const response = useData({
                swrOptions: {
                    revalidateOnFocus: false,
                    ...input == null ? void 0 : input.swrOptions
                }
            });
            return useMemo(()=>{
                return Object.create(response, {
                    isEmpty: {
                        get () {
                            var _response_data, _response_data_lines;
                            return (((_response_data = response.data) == null ? void 0 : (_response_data_lines = _response_data.lines) == null ? void 0 : _response_data_lines.length) ?? 0) <= 0;
                        },
                        enumerable: true
                    }
                });
            }, [
                response
            ]);
        };
    }
};
