const Cart = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.6,11.182l1.754-7.719a.891.891,0,0,0-.868-1.088H5.908L5.568.712A.891.891,0,0,0,4.7,0H.891A.891.891,0,0,0,0,.891v.594a.891.891,0,0,0,.891.891H3.484L6.091,15.12a2.078,2.078,0,1,0,2.488.318h7.78a2.078,2.078,0,1,0,2.36-.386l.2-.9a.891.891,0,0,0-.868-1.088H8.094l-.243-1.187H18.73A.891.891,0,0,0,19.6,11.182Z"
        transform="translate(1 3)"
        fill="currentColor"/>
    </svg>
  )
}

export default Cart
