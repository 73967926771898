import { cartDetailsFragment } from "../queries/get-cart-query";
const cartLineItemAddMutation = /* GraphQL */ `
  mutation cartLineItemAdd(
    $cartId: ID!
    $lines: [CartLineInput!]!
  ) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      userErrors {
        code
        field
        message
      }
      cart {
        ...cartDetails
      }
    }
  }

  ${cartDetailsFragment}
`;
export default cartLineItemAddMutation;
