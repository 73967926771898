const Gallery = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.748,14.848v.525a1.575,1.575,0,0,1-1.575,1.575H1.575A1.575,1.575,0,0,1,0,15.373v-8.4A1.575,1.575,0,0,1,1.575,5.4H2.1v6.824a2.628,2.628,0,0,0,2.625,2.625Zm3.15-2.625v-8.4A1.575,1.575,0,0,0,17.323,2.25H4.724A1.575,1.575,0,0,0,3.15,3.825v8.4A1.575,1.575,0,0,0,4.724,13.8h12.6A1.575,1.575,0,0,0,18.9,12.223ZM8.4,5.4A1.575,1.575,0,1,1,6.824,3.825,1.575,1.575,0,0,1,8.4,5.4Zm-3.15,4.724L7.071,8.3a.394.394,0,0,1,.557,0l1.3,1.3L13.37,5.153a.394.394,0,0,1,.557,0L16.8,8.024V11.7H5.249Z"
        transform="translate(3 2.75)"
        fill="currentColor"/>
    </svg>
  )
}

export default Gallery
