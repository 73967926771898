import Cookies from "js-cookie";
import { SHOPIFY_CART_ID_COOKIE, SHOPIFY_CHECKOUT_URL_COOKIE, SHOPIFY_COOKIE_EXPIRE } from "../const";
import cartCreateMutation from "./mutations/cart-create";
export const cartCreate = async (fetch, lines)=>{
    console.log("lines", lines);
    const { cartCreate  } = await fetch({
        query: cartCreateMutation,
        variables: {
            input: {
                lines: lines
            }
        }
    });
    const cart = cartCreate == null ? void 0 : cartCreate.cart;
    console.log("cart", cart);
    if (cart) {
        const cartId = cart == null ? void 0 : cart.id;
        const options = {
            expires: SHOPIFY_COOKIE_EXPIRE
        };
        Cookies.set(SHOPIFY_CART_ID_COOKIE, cartId, options);
        if (cart == null ? void 0 : cart.checkoutUrl) {
            Cookies.set(SHOPIFY_CHECKOUT_URL_COOKIE, cart == null ? void 0 : cart.checkoutUrl, options);
        }
    }
    return cartCreate;
};
export default cartCreate;
