import {FC, useEffect, useState} from 'react'
import Link from 'next/link'
import s from './Navbar.module.scss'
import NavbarRoot from './NavbarRoot'
import {Container, Button} from '@components/ui'
import { UserNav} from '@components/common'
import Image from "next/image";
import {useRouter} from "next/router";
import {ShopNavigationColumn} from "@components/common/Layout/Layout";
import {useTranslation} from "next-i18next";
import {
  Bag,
  Cart,
  Stopwatch,
  BandOfBrothers,
  Gallery,
  News,
  Twitter,
  Facebook,
  Instagram,
  YouTube,
  ChevronRight, Podcast
} from '@components/icons'
import {fetchJson} from "../../../pages";
import Stories from "@components/icons/Stories";
import RoadCode from "@components/icons/roadcode";
import Tiktok from "@components/icons/tiktok";
import LinkedIn from "@components/icons/LinkedIn";

interface Link {
  href: string
  label: string
}

interface NavbarProps {
  links?: ShopNavigationColumn[]
}

const Navbar: FC<NavbarProps> = ({ links }) => {
  const { events } = useRouter();
  const [navbar,setNavbar]:any = useState(null);
  const { t } = useTranslation("common");
  const router = useRouter()
  const [language, setLanguage]: any = useState(router.locale)

  const [teamCollapsed, setTeamCollapsed]:any = useState(null);
  function toggleTeam() {
    setTeamCollapsed(teamCollapsed ? null : s.collapsed );
  }
  const [u19Collapsed, setU19Collapsed]:any = useState(null);
  function toggleU19() {
    setU19Collapsed(u19Collapsed ? null : s.collapsed );
  }
  const [partnerCollapsed, setPartnerCollapsed]:any = useState(null);
  function togglePartner() {
    setPartnerCollapsed(partnerCollapsed ? null : s.collapsed );
  }

  function  toggleNavbar() {
    setNavbar(navbar ? null : s.active);
  }

  function  closeNavbar() {
    navbar && setNavbar(null);
  }

  // Make sure nav is closed when navigating
  // Never open it this way, but if its open, close it
  useEffect(() => {
    events.on(
      'routeChangeStart',
      closeNavbar
    );
    return () => {
      events.off(
        'routeChangeStart',
        closeNavbar
      );
    };
  }, [closeNavbar, events]);

  const changeCase = (str: string, isLower = false) =>
    isLower ? str.toLowerCase() : str.toUpperCase()

  /*TODO get all pages from server, and store localized slugs in array dynamically */
  const dynamicPageSlugs = [
    {
      en: 'press',
      de: 'presse'
    },
    {
      en: 'contact',
      de: 'kontakt'
    },
    {
      en: 'imprint',
      de: 'impressum'
    },
    {
      en: 'about',
      de: 'about'
    },
    {
      en: 'jobs',
      de: 'jobs'
    }
  ]

  const onChangeLanguage = (lang: string) => async (e: any)=> {
    console.log("onChangeLanguage", e);
    e.preventDefault()
    let path: any = router.asPath;

    if (window && window.location) {
      const url = window.location.href;
      let isDynamicPage = false;
      dynamicPageSlugs.forEach((x: any) => {
        if (url.indexOf(x[language]) !== -1) {
          isDynamicPage = true;
          path = {
            pathname: `/${lang}/${x[lang]}`
          }
        }
      });

      if (!isDynamicPage && lang !== language) {
        const newsStringIndex = url.indexOf('/news');
        /* IF USER SWITCHES LANGUAGE ON A NEWS ARTICLE, CHECK
        FOR THE CORRECT LANGUAGE'S SLUG AND FORWARD TO THAT URL*/
        if (newsStringIndex !== -1) {
          const slug = url.substr(newsStringIndex + 6).split('/').pop();
          console.log("slug", slug);
          // get the other language slug and push it to the router
          const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"}/api/articles?populate=*&filters[slug][$eq]=${slug}&locale=${language}`);
          let articles = await response.json();
          console.log("articles", articles);

          if (articles?.data[0]) {
            const article = articles.data[0];
            // Since there is only one other language we just take index 0, if we add more languages
            // we would need to loop and check for a matching locale
            const slugInOtherLanguage = article.attributes.localizations?.data[0].attributes?.Slug;
            if (slugInOtherLanguage) {
              path = {
                pathname: `/${lang}/news/${slugInOtherLanguage}`
              }
            }
          }
        }
      }
    }

    setLanguage(lang);
    if(lang !== 'en') {
      router.push(path, undefined, { locale: lang })
    } else {
      router.push(path, undefined, { locale: 'en' })
    }
  }


  return (
    <NavbarRoot>
      <Container clean className={[s.navContainer, navbar].join(' ')}>

        <div className={s.navBar}>
          <div className={s.toggleContainer}>
            <div className={s.mobileCartButton}>
              <UserNav />
            </div>
            <div className={s.toggle} onClick={()=>{toggleNavbar()}}>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>


          <div className={s.userNav}>
            <UserNav />
          </div>

          <div style={{marginBottom: "50px"}} className={s.socialNav}>
            <div className="mb-3">
              <Link legacyBehavior href="https://www.facebook.com/redbullBORAhansgrohe">
                <a target="_blank">
                  <Facebook></Facebook>
                </a>
              </Link>
            </div>

            <div className="mb-3">
              <Link legacyBehavior href="https://x.com/RBH_ProCycling">
                <a target="_blank">
                  <Twitter></Twitter>
                </a>
              </Link>
            </div>

            <div className="mb-3">
              <Link legacyBehavior href="https://www.youtube.com/channel/UC8rlqRMt762GzKCQl0CwBwg">
                <a target="_blank">
                  <YouTube></YouTube>
                </a>
              </Link>
            </div>

            <div className="mb-3">
              <Link legacyBehavior href="https://www.instagram.com/redbullborahansgrohe">
                <a target="_blank">
                  <Instagram></Instagram>
                </a>
              </Link>
            </div>

            <div className="mb-3">
              <Link legacyBehavior href="https://www.roadcode.cc/no-access">
                <a target="_blank">
                  <RoadCode></RoadCode>
                </a>
              </Link>
            </div>

            <div className="mb-3">
              <Link legacyBehavior href="https://www.tiktok.com/@borahansgroheofficial">
                <a target="_blank">
                  <Tiktok></Tiktok>
                </a>
              </Link>
            </div>

            <div>
              <Link legacyBehavior href="https://www.linkedin.com/company/redbull-bora-hansgrohe">
                <a target="_blank">
                  <LinkedIn></LinkedIn>
                </a>
              </Link>
            </div>

          </div>
        </div>
      </Container>
      <div className={s.navMenu}>
        <div className={s.menuBody}>
          <Container className="container-rg">
            <header>
              <Link legacyBehavior href="/">
                <a className={s.logo} aria-label="Logo">
                  <Image
                    className={s.img}
                    src="/bora-hansgrohe.svg"
                    alt="BORA hansgrohe German Professional Cycling"
                    width={140}
                    height={74}
                    priority={false}
                    quality="85"
                  />
                </a>
              </Link>

              <ul className={s.topLang}>
                <li className="md:mt-0 mt-4 mb-2">
                  <button onClick={onChangeLanguage('de')}>
                    <span className={language === 'de' ? 'text-accent-7' : ''}>Deutsch</span>
                  </button>
                  <span className="mx-2">|</span>
                  <button onClick={onChangeLanguage('en')}>
                    <span className={language === 'en' ? 'text-accent-7' : ''}>English</span>
                  </button>
                </li>
              </ul>
            </header>
            <nav className={s.websiteNav}>
              <div className="md:w-1/2 lg:w-1/5 lg:bg-brand">
                <ul>
                  <li className={s.link}>
                    <a className="lg:text-white" href={'/' + language + '/' + changeCase(t("news"), true)}><span className={s.navIcon}><News></News></span> {t("news")}</a> {/* use regular a tag on purpose here*/}
                  </li>
                  <li className={s.link}>
                    <a className="lg:text-white"  href={'/' + language + '/' + changeCase(t("races"), true)}><span className={s.navIcon}><Stopwatch></Stopwatch></span> {t("races")}</a> {/* use regular a tag on purpose here*/}
                  </li>
                  <li className={s.link}>
                    <Link legacyBehavior href={'/' + language + '/' + changeCase(t("galleries"), true)}>
                      <a className="lg:text-white" ><span className={s.navIcon}><Gallery></Gallery></span>{t("galleries")}</a>
                    </Link>
                  </li>
                  <li className={s.link}>
                    <Link legacyBehavior href={'/' + language + '/about'}>
                      <a className="lg:text-white" ><span className={s.navIcon}><BandOfBrothers></BandOfBrothers></span> {t("about")}</a>
                    </Link>
                  </li>
                  <li className={s.link}>
                    <Link legacyBehavior href={'/' + language + '/podcast'}>
                      <a className="lg:text-white" ><span className={s.navIcon}><Podcast></Podcast></span> Podcast</a>
                    </Link>
                  </li>
                  <li className={s.link}>
                    <Link legacyBehavior href={'/' + language + '/stories'}>
                      <a className="lg:text-white" ><span className={s.navIcon}><Stories></Stories></span> Stories</a>
                    </Link>
                  </li>
                </ul>
              </div>

              <ul className="md:w-1/2 lg:w-1/5">
                <li className={s.link}>
                  <div className={s.collapsibleLink}>
                    <a className="flex-1" href={'/' + language + '/' + changeCase(t("riders"), true)}>Team</a> {/* Use regular <a> on purpose*/}
                    <Button variant={"naked"}  onClick={()=>toggleTeam()}>
                      <ChevronRight></ChevronRight>
                    </Button>

                  </div>

                  <ul className={teamCollapsed}>
                    <li className={s.link}>
                      <a href={'/' + language + '/' + changeCase(t("riders"), true)}>{t("riders")}</a> {/* Use regular <a> on purpose*/}
                    </li>
                    <li className={s.link}>
                      <Link legacyBehavior href={'/' + changeCase(t("management"), true)}>
                        <a>Management</a>
                      </Link>
                    </li>
                    <li className={s.link}>
                      <Link legacyBehavior href={'/' + changeCase(t("history"), true)}>
                        <a>{t("history")}</a>
                      </Link>
                    </li>
                    <li className={s.link}>
                      <Link legacyBehavior href={'/' + changeCase(t("scouting"), true)}>
                        <a>{t("scouting")}</a>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul className="md:w-1/3 lg:w-1/5 lg:bg-accent-2">
                <li className={s.link}>
                  <div className={s.collapsibleLink}>
                    <a className="flex-1" href={'/' + language + '/' + changeCase(t("riders"), true) + '/U19'}>U19</a> {/* Use regular <a> on purpose*/}
                    <Button variant={"naked"}  onClick={()=>toggleU19()}>
                      <ChevronRight></ChevronRight>
                    </Button>

                  </div>
                  <ul className={u19Collapsed}>
                    <li className={s.link}>
                      <a href={'/' + language + '/' + changeCase(t("news"), true) + '/U19'}>News</a> {/* Use regular <a> on purpose*/}
                    </li>
                    <li className={s.link}>
                      <a href={'/' + language + '/' + changeCase(t("riders"), true) + '/U19'}>Team</a> {/* Use regular <a> on purpose*/}
                    </li>
                    <li className={s.link}>
                      <a href={'/' + language + '/' + changeCase(t("sponsors"), true) + "/U19"}>{t("sponsors")}</a> {/* Use regular <a> on purpose*/}
                    </li>
                    <li className={s.link}>
                      <a href={"/" + language + '/' + changeCase(t("races"), true) + "/U19"}>{t("races")} </a> {/* Use regular <a> on purpose*/}
                    </li>
                  </ul>
                </li>
              </ul>
              <ul className="md:w-1/3 lg:w-1/5">
                <li className={s.link}>
                  <div className={s.collapsibleLink}>
                    <a className={'flex-1'} href={'/' + language + '/' + changeCase(t("sponsors"), true)}>{t("partner")}</a> {/* Use regular <a> on purpose*/}
                    <Button variant={"naked"}  onClick={()=>togglePartner()}>
                      <ChevronRight></ChevronRight>
                    </Button>

                  </div>
                  <ul className={partnerCollapsed}>
                    <li className={s.link}>
                      <a href={'/' + language + '/' + changeCase(t("sponsors"), true)}>{t("sponsors")}</a> {/* Use regular <a> on purpose*/}
                    </li>
                    <li className={s.link}>
                      <Link legacyBehavior href="/partners/bora">
                        <a>BORA</a>
                      </Link>
                    </li>
                    <li className={s.link}>
                      <Link legacyBehavior href="/partners/hansgrohe">
                        <a>hansgrohe</a>
                      </Link>
                    </li>
                    <li className={s.link}>
                      <Link legacyBehavior href="/partners/specialized">
                        <a>Specialized</a>
                      </Link>
                    </li>
                    <li className={s.link}>
                      <Link legacyBehavior href="/partners/sportful">
                        <a>Sportful</a>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>

              <div className="hidden md:block md:w-1/3 lg:w-1/5 md:border-l-accent-2 md:border-l mt-4 md:mt-0">
                <div className="text-sm text-accent-3">
                  <ul>
                    <li className="mb-2">
                      <a onClick={onChangeLanguage('de')}>
                        <span className={language === 'de' ? 'text-accent-7' : ''}>Deutsch</span>
                      </a>
                      <span className="mx-2">|</span>
                      <a onClick={onChangeLanguage('en')}>
                        <span className={language === 'en' ? 'text-accent-7' : ''}>English</span>
                      </a>
                    </li>
                    <li>
                      <Link legacyBehavior href={'/' + changeCase(t("press"), true)}>
                        <a>{t("press")}</a>
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={'/' + changeCase(t("press"), true) + '/' + changeCase(t("download"), true)}>
                        <a>{t("download")}</a>
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={'/' + changeCase(t("jobs"), true)}>
                        <a>{t("jobs")}</a>
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={'/' + changeCase(t("contact", ), true)}>
                        <a>{t("contact")}</a>
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={'/' + changeCase(t("imprint"), true)}>
                        <a>{t("imprint")}</a>
                      </Link>
                    </li>
                  </ul>
                  <div className="flex pl-2 mt-4 md:mt-0">
                    <div className="mr-2">
                      <Link legacyBehavior href="https://www.facebook.com/redbullBORAhansgrohe">
                        <a target="_blank">
                          <Facebook></Facebook>
                        </a>
                      </Link>
                    </div>

                    <div className="mr-2">
                      <Link legacyBehavior href="https://x.com/RBH_ProCycling">
                        <a target="_blank">
                          <Twitter></Twitter>
                        </a>
                      </Link>
                    </div>

                    <div className="mr-2">
                      <Link legacyBehavior href="https://www.youtube.com/channel/UC8rlqRMt762GzKCQl0CwBwg">
                        <a target="_blank">
                          <YouTube></YouTube>
                        </a>
                      </Link>
                    </div>

                    <div className="mr-2">
                      <Link legacyBehavior href="https://www.instagram.com/redbullborahansgrohe">
                        <a target="_blank">
                          <Instagram></Instagram>
                        </a>
                      </Link>
                    </div>

                    <div className="mr-2">
                      <Link legacyBehavior href="https://www.roadcode.cc/no-access">
                        <a target="_blank">
                          <RoadCode></RoadCode>
                        </a>
                      </Link>
                    </div>

                    <div className="mr-2">
                      <Link legacyBehavior href="https://www.tiktok.com/@borahansgroheofficial">
                        <a target="_blank">
                          <Tiktok></Tiktok>
                        </a>
                      </Link>
                    </div>

                    <div>
                      <Link legacyBehavior href="https://www.linkedin.com/company/redbull-bora-hansgrohe">
                        <a target="_blank">
                          <LinkedIn></LinkedIn>
                        </a>
                      </Link>
                    </div>

                  </div>
                </div>
              </div>

            </nav>
            <nav className={s.shopNav}>
              {
                links?.map((link: ShopNavigationColumn, i: any) => {
                  return (
                    <ul key={i}>
                      <li className={s.link}>
                        {
                          link.href.includes('shop') ?
                            <>
                              <Link legacyBehavior href={`/${link.href}`}>
                                <a><span className={s.navIcon}><Cart></Cart></span> {link.label}</a>
                              </Link>
                            </> : <Link legacyBehavior href={`/search${link.href}`}>
                              <a>{link.label}</a>
                            </Link>
                        }
                        {
                          link.ShopNavigationItem?.length > 0 ?
                            <ul>{
                              link.ShopNavigationItem.map((subLink, x: any) => {
                                return (
                                  <li key={x} className={s.link}>
                                    <Link legacyBehavior href={`/search${subLink.href}`}>
                                      <a>{subLink.label}</a>
                                    </Link>
                                  </li>
                                )
                              })
                            }</ul> : null
                        }
                      </li>
                    </ul>
                  )
                })
              }
              {/*<ul>
                <li className={s.link}>
                  <Link legacyBehavior href="/shop">
                    <a>Shop</a>
                  </Link>
                </li>
              </ul>
              <div className="ml-4 lg:ml-6 flex">
                <ul>
                  <li className={s.link}>
                    <Link legacyBehavior href="/shop">
                      <a>Performance</a>
                    </Link>
                    <ul>
                      <li className={s.link}>
                        <Link legacyBehavior href="/shop">
                          <a>Trikots</a>
                        </Link>
                      </li>
                      <li className={s.link}>
                        <Link legacyBehavior href="/shop">
                          <a>Hosen</a>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li className={s.link}>
                    <Link legacyBehavior href="/shop">
                      <a>Lifestyle</a>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li className={s.link}>
                    <Link legacyBehavior href="/shop">
                      <a>Gear</a>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li className={s.link}>
                    <Link legacyBehavior href="/shop">
                      <a>Glasses</a>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li className={s.link}>
                    <Link legacyBehavior href="/shop">
                      <a>Accessoires</a>
                    </Link>
                  </li>
                </ul>
              </div>*/}
              <ul className={s.mobileLang}>
                <li className="md:mt-0 mt-4 mb-2">
                  <button onClick={onChangeLanguage('de')}>
                    <span className={language === 'de' ? 'text-accent-7' : ''}>Deutsch</span>
                  </button>
                  <span className="mx-2">|</span>
                  <button onClick={onChangeLanguage('en')}>
                    <span className={language === 'en' ? 'text-accent-7' : ''}>English</span>
                  </button>
                </li>
              </ul>
            </nav>
            <div className=" md:hidden mt-4">
              <div className="text-sm text-accent-3">
                <ul>
                  <li className="mb-2">
                    <a onClick={onChangeLanguage('de')}>
                      <span className="text-accent-7">Deutsch</span>
                    </a>
                    <span className="mx-2">|</span>
                    <a onClick={onChangeLanguage('en')}>
                      <span>English</span>
                    </a>
                  </li>
                  <li>
                    <Link legacyBehavior href={'/' + changeCase(t("press"), true)}>
                      <a>{t("press")}</a>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href={'/' + changeCase(t("jobs"), true)}>
                      <a>{t("jobs")}</a>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href={'/' + changeCase(t("contact", ), true)}>
                      <a>{t("contact")}</a>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href={'/' + changeCase(t("imprint"), true)}>
                      <a>{t("imprint")}</a>
                    </Link>
                  </li>
                </ul>
                <div className="flex pl-2 mt-4 md:mt-0">
                  <div className="mr-2">
                    <Link legacyBehavior href="https://www.facebook.com/redbullBORAhansgrohe">
                      <a target="_blank">
                        <Facebook></Facebook>
                      </a>
                    </Link>
                  </div>

                  <div className="mr-2">
                    <Link legacyBehavior href="https://x.com/RBH_ProCycling">
                      <a target="_blank">
                        <Twitter></Twitter>
                      </a>
                    </Link>
                  </div>

                  <div className="mr-2">
                    <Link legacyBehavior href="https://www.youtube.com/channel/UC8rlqRMt762GzKCQl0CwBwg">
                      <a target="_blank">
                        <YouTube></YouTube>
                      </a>
                    </Link>
                  </div>

                  <div className="mr-2">
                    <Link legacyBehavior href="https://www.instagram.com/redbullborahansgrohe">
                      <a target="_blank">
                        <Instagram></Instagram>
                      </a>
                    </Link>
                  </div>

                  <div className="mr-2">
                    <Link legacyBehavior href="https://www.roadcode.cc/no-access">
                      <a target="_blank">
                        <RoadCode></RoadCode>
                      </a>
                    </Link>
                  </div>

                  <div className="mr-2">
                    <Link legacyBehavior href="https://www.tiktok.com/@borahansgroheofficial">
                      <a target="_blank">
                        <Tiktok></Tiktok>
                      </a>
                    </Link>
                  </div>

                  <div>
                    <Link legacyBehavior href="https://www.linkedin.com/company/redbull-bora-hansgrohe">
                      <a target="_blank">
                        <LinkedIn></LinkedIn>
                      </a>
                    </Link>
                  </div>

                </div>
              </div>
            </div>

          </Container>
        </div>
        <div className={s.backDrop} onClick={() => {
          toggleNavbar()
        }}></div>
      </div>
    </NavbarRoot>
  )
}

export default Navbar
