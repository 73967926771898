export const cartDetailsFragment = /* GraphQL */ `
  fragment cartDetails on Cart {
    id
    checkoutUrl
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
      totalTaxAmount {
        amount
        currencyCode
      }
      totalAmount {
        amount
        currencyCode
      }
    }
    createdAt
    lines(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          merchandise {
            ... on ProductVariant {
              id
              sku
              title
              selectedOptions {
                name
                value
              }
              requiresShipping
              image {
                originalSrc
                altText
                width
                height
              }
              priceV2 {
                amount
                currencyCode
              }
              compareAtPriceV2 {
                amount
                currencyCode
              }
              product {
                handle
                title
                id
              }
            }
          }
          quantity
        }
      }
    }
  }
`;
const getCartQuery = /* GraphQL */ `
  query getCart($cartId: ID!) {
    node(id: $cartId) {
      ...cartDetails
    }
  }
  ${cartDetailsFragment}
`;
export default getCartQuery;
