import { useCallback } from "react";
import { ValidationError } from "@vercel/commerce/utils/errors";
import useRemoveItem from "@vercel/commerce/cart/use-remove-item";
import useCart, { addRecommendedProducts } from "./use-cart";
export default useRemoveItem;
import { normalizeCart } from "../utils";
import cartLineItemRemoveMutation from "../utils/mutations/cart-line-item-remove";
import getCartId from "../utils/get-cart-id";
export const handler = {
    fetchOptions: {
        query: cartLineItemRemoveMutation
    },
    async fetcher ({ input: { itemId  } , options , fetch  }) {
        const data = await fetch({
            ...options,
            variables: {
                cartId: getCartId(),
                lineIds: [
                    itemId
                ]
            }
        });
        // @ts-ignore
        let cart = normalizeCart(data.cartLinesRemove.cart);
        if (!cart) {
            return undefined;
        }
        const cartLineItems = cart.lines;
        const products = cartLineItems.map((lineItem)=>lineItem.product);
        cart = await addRecommendedProducts(cart, products, fetch);
        console.log("cart", cart);
        return cart;
    },
    useHook: ({ fetch  })=>{
        return (ctx = {})=>{
            const { item  } = ctx;
            const { mutate  } = useCart();
            const removeItem = async (input)=>{
                const itemId = (input == null ? void 0 : input.id) ?? (item == null ? void 0 : item.id);
                if (!itemId) {
                    throw new ValidationError({
                        message: "Invalid input used for this operation"
                    });
                }
                const data = await fetch({
                    input: {
                        itemId
                    }
                });
                await mutate(data, false);
                return data;
            };
            return useCallback(removeItem, [
                fetch,
                mutate
            ]);
        };
    }
};
