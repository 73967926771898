const BrandOfBrothers = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.089,4.812C17.089,2.178,15.01,0,11.723,0A6.779,6.779,0,0,0,4.594,6.772,6.231,6.231,0,0,0,5.287,9.6L6.752,8.515a4.257,4.257,0,0,1-.455-1.9A5.09,5.09,0,0,1,11.5,1.624c2.416,0,3.7,1.446,3.7,3.129,0,2.5-2.535,3.6-4.574,2.911l.653-1.643a3.443,3.443,0,0,1,1.743-2l-.376-1.248A3.774,3.774,0,0,0,9.465,5.347l-2.257,5.6A17.884,17.884,0,0,1,6,13.445a5.213,5.213,0,0,0-3.069-1.228A2.772,2.772,0,0,0,0,14.97a2.743,2.743,0,0,0,3.05,2.654,4.657,4.657,0,0,0,3.525-1.7C8.257,17.228,9.525,18,11.208,18a4.766,4.766,0,0,0,4.673-5.03,4.69,4.69,0,0,0-2.257-4.04,4.227,4.227,0,0,0,3.465-4.119M2.97,15.94A1.232,1.232,0,0,1,1.6,14.891a1.277,1.277,0,0,1,1.327-1.168,4.092,4.092,0,0,1,2.158,1.01A3,3,0,0,1,2.97,15.94m10.99-3.248c0,1.465-.852,3.485-2.97,3.485a5.715,5.715,0,0,1-3.426-1.545,17.271,17.271,0,0,0,1.287-2.574c.3-.693.772-1.881,1.228-3.03,3.485.772,3.881,2.693,3.881,3.663"
        fill="currentColor"/>
    </svg>
  )
}

export default BrandOfBrothers
