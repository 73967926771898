import { cartDetailsFragment } from "../queries/get-cart-query";
const cartLineItemRemoveMutation = /* GraphQL */ `
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(
      cartId: $cartId
      lineIds: $lineIds
    ) {
      userErrors {
        field
        message
      }
      cart {
        ...cartDetails
      }
    }
  }
  ${cartDetailsFragment}
`;
export default cartLineItemRemoveMutation;
