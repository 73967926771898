const Stopwatch = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.773,9.973A6.824,6.824,0,1,1,6.9,3.231V2.1H5.981a.4.4,0,0,1-.394-.394V.394A.4.4,0,0,1,5.981,0H9.918a.4.4,0,0,1,.394.394V1.706a.4.4,0,0,1-.394.394H9V3.231A6.783,6.783,0,0,1,12.27,4.695l.9-.9a.394.394,0,0,1,.558,0l.928.928a.394.394,0,0,1,0,.558l-.965.965-.02.02A6.755,6.755,0,0,1,14.773,9.973ZM9,11.154V6.184a.4.4,0,0,0-.394-.394H7.293a.4.4,0,0,0-.394.394v4.97a.4.4,0,0,0,.394.394H8.605A.4.4,0,0,0,9,11.154Z"
        transform="translate(3.875 4)"
        fill="currentColor"/>
    </svg>
  )
}

export default Stopwatch
