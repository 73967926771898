import { useCallback } from "react";
import { CommerceError } from "@vercel/commerce/utils/errors";
import useAddItem from "@vercel/commerce/cart/use-add-item";
import useCart, { addRecommendedProducts } from "./use-cart";
import { cartCreate, normalizeCart } from "../utils";
import getCartId from "../utils/get-cart-id";
import cartLineItemAddMutation from "../utils/mutations/cart-line-item-add";
import validateCart from "../utils/validate-cart";
export default useAddItem;
export const handler = {
    fetchOptions: {
        query: cartLineItemAddMutation
    },
    async fetcher ({ input: item , options , fetch  }) {
        if (item.quantity && (!Number.isInteger(item.quantity) || item.quantity < 1)) {
            console.error("No quantity available for item", item);
            throw new CommerceError({
                message: "The item quantity has to be a valid integer greater than 0"
            });
        }
        const lines = [
            {
                merchandiseId: item.variantId,
                quantity: item.quantity ?? 1
            }
        ];
        console.log("lines", lines);
        let cartId = getCartId();
        console.log("cartId", cartId);
        if (!cartId) {
            return validateCart(await cartCreate(fetch, lines));
        } else {
            const { cartLinesAdd  } = await fetch({
                ...options,
                variables: {
                    cartId,
                    lines
                }
            });
            console.log("cartLinesAdd", cartLinesAdd);
            let cart = normalizeCart(cartLinesAdd.cart);
            console.log("cart 1", cart);
            if (!cart) {
                return undefined;
            }
            const cartLineItems = cart.lineItems;
            const products = cartLineItems.map((lineItem)=>lineItem.product);
            cart = await addRecommendedProducts(cart, products, fetch);
            console.log("cart 2", cart);
            return cart;
        }
    },
    useHook: ({ fetch  })=>()=>{
            const { mutate  } = useCart();
            return useCallback(async function addItem(input) {
                const data = await fetch({
                    input
                });
                await mutate(data, false);
                return data;
            }, [
                fetch,
                mutate
            ]);
        }
};
