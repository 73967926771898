import { FC } from 'react'
import cn from 'clsx'
import Link from 'next/link'
import type { Page } from '@commerce/types/page'
import { Container } from '@components/ui'
import s from './Footer.module.scss'
import Image from "next/image";
import {useTranslation} from "next-i18next";
import SignupForm from "@components/CmsBlocks/Newsletter";

interface Props {
  className?: string
  children?: any
  pages?: Page[]
}

const Footer: FC<Props> = ({ className }) => {
  const rootClassName = cn(s.root, className);
  const { t } = useTranslation("common");
  const year = new Date().getFullYear();
  const changeCase = (str: string, isLower = false, encode = true) => {

    if (isLower) {
      str = str.toLowerCase();
    } else {
      str = str.toUpperCase();
    }
    if (encode) {
      str = str.replace(/\s+/g, '-')
    } // replace spaces with - for url's
    return str;
  }
  return (
    <footer className={rootClassName}>
      <Container>
        <div className="flex flex-wrap justify-center md:justify-between align-middle px-2">
          <div className="self-center text-center order-4 lg:order-1">
            <span className="md:mr-6 text-accent-4">© {year} Red Bull - BORA - hansgrohe</span>
          </div>
          <div className="self-center grow order-1 lg:order-2 text-center md:text-left">
            <Link legacyBehavior href={'/' + changeCase(t("imprint"), true)}>
              <a className={s.link}>{t("imprint")}</a>
            </Link>
            <Link legacyBehavior href={'/' + changeCase(t("data_protection"), true)}>
              <a className={s.link}>{t("data_protection")}</a>
            </Link>
            <Link legacyBehavior href={'/' + changeCase(t("contact"), true)}>
              <a className={s.link}>{t("contact")}</a>
            </Link>
            <Link legacyBehavior href={'/' + changeCase(t("jobs"), true)}>
              <a className={s.link}>{t("jobs")}</a>
            </Link>
            <Link legacyBehavior href={'/' + changeCase(t("returns"), true)}>
              <a className={s.link}>{t("returns")}</a>
            </Link>
            <Link legacyBehavior href={'/newsletter'}>
              <a className={s.link}>Newsletter</a>
            </Link>
          </div>

          <div className="flex flex-wrap justify-center gap-2 my-4 lg:my-0 order-2 lg:order-3">
            <div className={s.iconCard}>
              <Image
                src="/icons/icon-visa.svg"
                alt={t("creditcard")}
                width={16}
                height={16}
                priority={false}
                quality="85"
              />
              <div>Visa</div>
            </div>
            <div className={s.iconCard}>
              <Image
                src="/icons/icon-mastercard.svg"
                alt={t("creditcard")}
                width={16}
                height={16}
                priority={false}
                quality="85"
              />
              <div>Mastercard</div>
            </div>

            <div className={s.iconCard}>
              <Image
                src="/icons/icon-maestro.svg"
                alt={t("creditcard")}
                width={16}
                height={16}
                priority={false}
                quality="85"
              />
              <div>Maestro</div>
            </div>

            <div className={s.iconCard}>
              <Image
                src="/icons/icon-sofort.svg"
                alt={t("sofort")}
                width={16}
                height={16}
                priority={false}
                quality="85"
              />
              <div>{t("sofort")}</div>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}

// Sort pages by the sort order assigned in the BC dashboard
function bySortOrder(a: Page, b: Page) {
  return (a.sort_order ?? 0) - (b.sort_order ?? 0)
}

export default Footer
