import Image from "next/image";

const RoadCode = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
      <rect width="400" height="400" rx="107" fill="currentColor"/>
      <path
        d="M153.468,47.638H106.874a58.9,58.9,0,0,0-58.819,58.853v75.565H14V106.491a92.9,92.9,0,0,1,92.873-92.932h75.6V47.63h-29"
        transform="translate(101.763 101.874)" fill="#fff"/>
      <path
        d="M67.586,101.456A13.332,13.332,0,0,1,54.262,88.116V66.177A12.339,12.339,0,0,1,66.59,53.832h68.542a.747.747,0,0,0,.758-.749V20.519a.758.758,0,0,0-.758-.766H61.107A40.924,40.924,0,0,0,20.2,60.7V88.116a47.394,47.394,0,0,0,47.386,47.42h67.545a.754.754,0,0,0,.758-.758V102.205a.758.758,0,0,0-.758-.749Z"
        transform="translate(148.339 148.412)" fill="#fff"/>
    </svg>
  )
}

export default RoadCode
